import React from 'react';
import './HeroSection.css'; // make sure to create a corresponding CSS file
import video from '../images/promo.mp4';
import video1 from '../images/promo.webm';
import video2 from '../images/promo2.ogv';
import logo from '../images/1331s.PNG';
import poster from "../images/poster.png"
import BookNow from './BookNow';

function Hero() {
    return (
        <section className="hero-section">
            <video  autoPlay loop muted playsInline poster={poster} className="background-video">
                {/* <source src={video} poster={poster} type="video/mp4" /> */}
                <source src={video1}  type="video/webm" />
                {/* <source src={video2} type="video/ogv" /> */}
                Your browser does not support the video tag.
            </video>
            <div className="hero-text">
                <br/>
                <h1>LEVEL UP YOUR MUSIC</h1>
                <h2>UNLEASH YOUR SOUND</h2>
                <img className="hero-logo" src={logo}></img>
            </div>
            <BookNow/>
        </section>
    );
}
export default Hero;