import React,{useEffect} from 'react';
import FadeInSection from "./FadeInSection";

const Mission = () => {
  useEffect(()=>{
    window.scrollTo(0, 0)
},[])

  return (
    <div className='mission-section'id="mission">
        <FadeInSection>
          <h1>Welcome to 1331 Recordz</h1>
          <h2>Where the fusion of innovation and creativity unfolds. More than a record label or production company, it's a dynamic movement.</h2>
          <br/>
        </FadeInSection>
        <FadeInSection>
          <h2>Here, artists discover that networking, creating, and embracing authenticity can elevate their careers beyond imagination. At its core, 1331 Recordz is a pioneering community of relentless go-getters, waking up each morning fueled by the passion to achieve greatness.</h2>
          <br/>
          <h2>Our mission is clear: to empower, inspire, and enlighten creatives globally. Join our community today and embark on a transformative journey that transcends boundaries, unlocking the full potential of your artistic expression</h2>
        </FadeInSection>
    </div>
  );
};
export default Mission;