import React from 'react';
import './HeroSection.css'; // make sure to create a corresponding CSS file
import video from '../images/promo.mp4';
import logo from '../images/1331s.PNG';
import Loading from './Loading';

function Hero2() {
    return (
        <section className="hero-section2">
            <div className="overlay">
                <h2>Elite Services</h2>
                <br/>
                <h3>Powered by the best engineers, producers, and musicians</h3>
            </div>
        </section>
    );
}
export default Hero2;