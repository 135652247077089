import React from 'react';
import './ServiceSection.css';
import Hero2 from './Hero2';
import { HiAdjustments } from "react-icons/hi";
import dj from "../images/dj2.png"
import beats from "../images/beats.png"
import song from "../images/songwriting.png"
import mixing from "../images/mixing.png"
import musicVideo from "../images/musicVideo.png"
import record from "../images/music-record-96.png"
import BookNow from './BookNow';

// Service data could also come from props or a state management store
const services = [
  {
    id: 1,
    name: 'MUSIC RECORDING',
    description: 'Experience unparalleled sound quality and expert production in our cutting-edge studio',
    iconPath: record
  },
  {
    id: 2,
    name: 'MUSIC VIDEOS',
    description: 'Transform your song into a visual masterpiece where cutting-edge cinematography meets creative storytelling',
    iconPath: musicVideo
  },
  {
    id: 3,
    name: 'BEATS AND INTRUMENTALS',
    description: 'Elevate your tracks with our custom, genre-spanning rhythms tailored to amplify your musical identity',
    iconPath: beats
  },
  {
    id: 4,
    name: 'MIXING AND MASTERING',
    description: 'Professional mixing and mastering that delivers industry-standard clarity and balance to every track',
    iconPath: mixing
  },
  {
    id: 5,
    name: 'SONG WRITING',
    description: 'Bring your musical vision to life. We merge lyrical creativity and melodic ingenuity to craft your next hit',
    iconPath: song
  }, 
  {
    id: 6,
    name: 'EVENT-PRODUCTION/DJ',
    description: 'Set the stage for an unforgettable event where expertly curated playlists and electrifying beats create the perfect ambiance',
    iconPath: dj
  }, 
];

const ServiceCard = ({ name, description, iconPath }) => (
  <div className="service">
    <img src={iconPath} alt={`${name} Icon`} />
    <h2>{name}</h2>
    <p>{description}</p>
  </div>
);

const ServicesSection = () => (
  <section id="main-services">
    <Hero2/>
    <div className="container">
      <div className="services-row">
        {services.slice(0, 3).map(service => (
          <ServiceCard key={service.id} {...service} />
        ))}
      </div>
      <div className="services-row">
        {services.slice(3, 6).map(service => (
          <ServiceCard key={service.id} {...service} />
        ))}
      </div>
    </div>
    <BookNow/>
  </section>
);

export default ServicesSection;