import React from 'react';
import ig from '../images/insta.png';
import twitter from '../images/xicon.png';
import discord from '../images/discord.png';
import youtube from '../images/youtube2.png';

//footer with social media icons
const Footer = () => {
  return (
    <div className='footer'>
        <div id="icons">
            <a href="https://www.youtube.com/@1331Recordz"><img src={youtube} alt="youtube"/></a>
            <a href="https://instagram.com/1331Recordz"><img src={ig} alt="ig"/></a>
            <a href="https://twitter.com/1331Recordz"><img src={twitter} alt="twitter"/></a>
            {/* <a href="https://discord.com/invite/G7nXe55dSE"><img src={discord} alt="discord"/></a> */}
        </div>
        <div id="copyright">
            <p>© 2022 1331 Recordz LLC. | All rights reserved.</p>
        </div>
    </div>
  );
};
export default Footer;