import React,{useEffect} from 'react';
import dardan from '../images/dardanbela.jpg';
import osaze from '../images/osazemoore.jpg';
import jake from '../images/jakehitz.jpg';
import adrian from '../images/adrianswish.jpg';
import shontelle from '../images/shontelle.jpg';
import simon from "../images/simon.jpeg"
import brian from '../images/briangibbs.jpg';
import FadeInSection from "./FadeInSection";


//meet the team
const TheTeam = () => {
  useEffect(()=>{
    window.scrollTo(0, 0)
},[])
  return (
    <div className='section' id="team">
        <FadeInSection>
        <h1>Our Team</h1>
        <div className="teammember">
          <div className='image'>
            <img src={dardan} alt="Dardan Bela" />
          </div>
          <div className='abt'>
            <h2>Dardan Bela</h2>
            <h3>Dardan, the visionary behind 1331 Recordz, pioneered a record label that is dedicated to maximizing artists potential. Fueled by a profound passion for music and a keen eye for talent, he created a supportive environment for artists to realize their aspirations. Working with notable artists like Shontelle Layne, Bobby Shmurda, Spottem Gottem, and others, Dardan is at the forefront of the music industry. Beyond 1331 Recordz, he co-founded IronGlass Byte, a cutting-edge software development company, leveraging a skilled team to craft sophisticated solutions tailored for diverse businesses.</h3>
          </div>
        </div>
        </FadeInSection>
        <FadeInSection>
        <div className="teammember">
            <div className='image'>
              <img src={osaze} alt="Osaze Moore" />
            </div>
            <div className='abt'>
              <h2>Osaze Moore</h2>
              <h3>Osaze Moore is an experienced IT professional who spent years implementing and developing cyber security and blockchain solutions. He is passionate and knowledgeable in the IT world and at 1331 Recordz he uses his skills to create new and innovative music technology solutions.</h3>
          </div>
        </div>
        </FadeInSection>
        <FadeInSection>
        <div className="teammember">
            <div className='image'>
              <img src={simon} alt="Simon Howard" />
            </div>
            <div className='abt'>
              <h2>Dr. Simon Howard, Ph.D</h2>
              <h3>Dr. Simon Howard is a multifaceted and multidimensional  leader with a profound appreciation for the intersection of psychology and music within society. With a background in social psychology and a deep understanding of the human psyche, Dr. Howard brings a unique perspective to his role as Chief of Operations Officer for 1331 Recordz. Dr. Howard leverages his academic background and passion for music to drive strategic initiatives that elevate artists' voices and amplify their impact on cultural discourse. He plays a pivotal role in shaping the label's artistic direction and fostering a diverse and inclusive creative environment.                                   Driven by a deep-seated belief in the transformative power of music, Dr. Howard is dedicated to fostering collaborations that transcend boundaries and promote social cohesion. Through visionary leadership, he seeks to cultivate a vibrant music industry that celebrates diversity, empowers artists, and fosters positive social change.</h3>
          </div>
        </div>
        </FadeInSection>
        <FadeInSection>
        <div className="teammember">
          <div className='image'>
            <img src={adrian} alt="Adrian Swish" />
          </div>
          <div className='abt'>
            <h2>Adrian Swish</h2>
            <h3>Veteran talent manager, entrepreneur, and founder, with a proven track record of success across several different platforms of the music industry including talent development, strategic digital marketing campaigns, music publishing, digital music distribution, live event production, branded content activations, and corporate partnerships.  Adrian Swish released his first whitepaper in 2018 with his idea of Music Distribution on Blockchain. Adrian’s professional accomplishments as a diversified leader that included talent management & artist development, will be utilized to propel 1331 Recordz to new heights for artist development</h3>
          </div>
        </div>
        </FadeInSection>
        <FadeInSection>
        <div className="teammember">
            <div className='image'>
              <img src={shontelle} alt="Shontelle Layne" />
            </div>
            <div className='abt'>
              <h2>Shontelle Layne</h2>
              <h3>Shontelle, most popularly known for her hit singles “Impossible,” & “T-shirt,” is one of the 1st black female mainstream pop-artist to enter the web3 space. One of the first songs Shontelle wrote was “Roll It Gal,” by Alison Hinds. The song became a Global hit. She also went on to co-write “Man Down,” the sixth single lifted from Rihanna’s fifth studio album “LOUD.” As of August 2012, “LOUD” was certified as the fifth biggest selling digital album of all time and Shontelle’s role on “Man Down” awarded her the title as a Grammy nominated songwriter. Shontelle’s exceptional music industry accomplishments gives her a cutting edge understanding of what it takes to make it as a web2 and web3 artist. Her role at 1331 Recordz will be as the chief marketing officer and community leader.</h3>
          </div>
        </div>
        </FadeInSection>
        
    </div>
  );
};
export default TheTeam;