//import React, {useState, useEffect} from 'react';
//import { APP_STATIC_PATH } from './Routes2';
import './App.css';
//import Loading from './components/Loading';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import Mission from './components/Mission';
import Services from './components/Services'
//import Merch from './components/Merch';
import TheTeam from './components/TheTeam';
//import CalendlyBooking from './CalendlyBooking';
import NxtUp from './components/NxtUp'
import { Route, Routes } from 'react-router-dom';



function App() {
  // const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2150);
  //   window.scrollTo(0, 0)
  // })
  return (

        <div className="App">
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mission" element={<Mission />} />
              <Route path="/services" element={<Services />} />
              <Route path="/1331Team" element={<TheTeam />} />
              {/* <Route path="/NXT-UP-Miami" element={<NxtUp />} /> */}
            </Routes>
            <Footer/>
        </div>
  );
}

export default App;
