import React, { useState, useEffect, useRef } from 'react';
import { Link } from  'react-router-dom';
import logo from '../images/1331s.PNG';
import close from '../images/x.png';
import open from '../images/hamburgerGreen.png';

const Navbar = (props) => {
  const [click, setClick] = useState(false);
  const [scrollState, setScrollState] = useState("top");
  const listenerRef = useRef(null);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    listenerRef.current = () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (scrollState !== "notTop") {
          setScrollState("notTop");
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top");
        }
      }
    };

    document.addEventListener("scroll", listenerRef.current);

    return () => {
      document.removeEventListener("scroll", listenerRef.current);
    };
  }, [scrollState]);

  return (
    <div className="parent">
      <nav
        className="nav sticky"
        style={{
          backgroundColor: scrollState === "top" ? "black" : "black",
          opacity: scrollState === "top" ? "100%" : "90%"
        }}
      >
        <div className="logo-nav">
          <div className="logo-container">
            <a href="/">
              <img src={logo} className="navlogo" alt="logo" />
            </a>
          </div>
          <ul className={click ? "nav-options active" : "nav-options"}>
            <li className="option" onClick={closeMobileMenu}>
              <a href="/">Home</a>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <Link to="/mission">The Mission</Link>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <Link to="/services">Services</Link>
            </li>
            {/* <li className="option" onClick={closeMobileMenu}>
                <Link to="/bookings">Bookings</Link>
            </li> */}
            {/* <li className="option" onClick={closeMobileMenu}>
              <Link to="/NXT-UP-Miami">NXT UP Miami</Link>
            </li> */}
            {/* <li className="option" onClick={closeMobileMenu}>
                <Link to="/merch">Merch</Link>
            </li> */}
            <li className="option" onClick={closeMobileMenu}>
              <Link to="/1331team">Meet The Team</Link>
            </li>
          </ul>
        </div>
        <div className="mobile-menu" onClick={handleClick}>
          {click ? (
            <img src={close} alt="" className="icon" />
          ) : (
            <img src={open} alt="" className="icon" />
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
